<template>
  <div>
    <v-row class="d-flex justify-end">
      <v-col>
        <h1>{{ $t("recommender.blacklist.listTitle") }}</h1>
      </v-col>
      <v-col cols="4" class="text-end">
        <v-btn
          small
          color="primary"
          :title="
            $t('recommender.blacklist.create', {
              scope: getServiceName({ scope: filters.target }),
            })
          "
          @click="editItem(null)"
        >
          {{ $t("recommender.blacklist.addGroup") }}
        </v-btn>
      </v-col>
    </v-row>
    <blacklist-search></blacklist-search>
    <v-row>
      <v-col sm="12" md="6" lg="6">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.2"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <!--    Data table     -->
        <v-data-iterator
          v-if="!dataLoading"
          hide-default-footer
          disable-filtering
          disable-sort
          :items="items"
          :no-data-text="$t('no-data')"
        >
          <template v-slot:default="props">
            <blacklist-item
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              :selected="viewedItem && item.id === viewedItem.id"
              @editInfo="editItem(item)"
              @viewInfo="viewItem(item)"
              @deleteItem="deleteConfirmation(item)"
            ></blacklist-item>
          </template>
        </v-data-iterator>
        <!--    TTable pagination -->
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
        <!--    Pagination    -->
        <div v-if="pageCount > 1" class="text-center pt-6">
          <v-pagination
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="9"
          ></v-pagination>
        </div>
        <!--  Setting form dialog    -->
        <v-dialog v-model="editDialog" max-width="700px">
          <blacklist-form
            v-if="editDialog"
            :item="selectedItem"
            :scope="filters.target"
            @close="closeEditDialog"
            @submitted="
              closeEditDialog();
              loadItems();
            "
          ></blacklist-form>
        </v-dialog>
        <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
          <v-card>
            <v-card-title
              class="headline primary lighten-1 white--text justify-space-between"
            >
              {{ $t("deleteConfirmation") }}
              <v-btn icon color="white" @click="deleteDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-2">
              {{ $t("deleteConfirmationText", { title: selectedItem.title }) }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="deleteDialog = false">{{
                $t("close")
              }}</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItem(selectedItem)"
              >
                {{ $t("delete") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <!--      <v-divider vertical></v-divider>-->
      <v-col sm="12" md="6" lg="6">
        <blacklist-list-item
          v-if="viewDialog && viewedItem"
          :listId="viewedItem.id"
          :listTitle="viewedItem.title"
          :selectedItem="filters.item"
        ></blacklist-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import BaseListPage from "@/views/base/BaseListPage";

import BlacklistItem from "@/views/recommender/blacklist/list/BlacklistItem";
import BlacklistForm from "@/views/recommender/blacklist/item/BlacklistForm";
import BlacklistSearch from "@/views/recommender/blacklist/list/BlacklistSearch";

import BlacklistListItem from "@/views/recommender/blacklist-item/BlacklistListItem";

import { mapGetters, mapState } from "vuex";
import { FETCH_SERVICES } from "@/store/actions/service";

import {
  defaultFilters,
  multipleFilters,
} from "@/store/modules/recommender/blacklist/state";

import {
  SET_FILTER,
  FETCH_BLACKLIST,
  DELETE_BLACKLIST,
} from "@/store/actions/recommender";

export default {
  name: "BlacklistList",
  extends: BaseListPage,
  components: {
    BlacklistItem,
    BlacklistForm,
    BlacklistSearch,
    BlacklistListItem,
  },
  data: function () {
    return {
      viewDialog: false,
      viewedItem: null,
      selectedTarget: 0,
      deleteDialog: false,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
    };
  },
  computed: {
    ...mapState("blacklist", ["pagination", "filters", "sort", "emptyFilters"]),
    ...mapGetters("service", ["scopes", "getServiceName"]),
    ...mapGetters("blacklist", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "availableScopes",
      "formattedFilters",
    ]),
  },
  methods: {
    viewItem: function (item) {
      this.viewedItem = item;
      this.viewDialog = true;
    },
    applyFilters: function (data) {
      this.$store.commit("blacklist/" + SET_FILTER, data.data);
    },
    loadServices: function () {
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    loadItems: function () {
      return new Promise((resolve, reject) => {
        this.dataLoading = true;
        this.$store
          .dispatch("blacklist/" + FETCH_BLACKLIST)
          .then(
            () => {
              this.dataLoading = false;
              if (
                this.viewedItem &&
                _.map(this.items, "id").indexOf(this.viewedItem.id) === -1
              ) {
                this.viewedItem = null;
                this.viewDialog = null;
              }
              resolve();
            },
            (err) => {
              console.log("Err", err);
              reject();
            }
          )
          .catch(() => {});
      });
    },
    deleteConfirmation: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      if (this.viewedItem && this.viewedItem.id === item.id) {
        this.selectedItem = null;
        this.viewDialog = false;
      }
      this.$store
        .dispatch("blacklist/" + DELETE_BLACKLIST, item)
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: this.$t("recommender.deleteSettings"),
                text: this.$t("recommender.deleteSettingsSuccess"),
              });
              this.dataLoading = false;
              this.selectedItem = null;
              this.deleteDialog = false;
              this.loadItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    if (!this.applyRouteParams()) {
      this.loadItems();
    }
    this.loadServices();
  },
  sockets: {
    blacklist: function (message) {
      this.$notify({
        group: "error",
        type: "error",
        title: this.$t("syncError"),
        text: message,
      });
    },
  },
};
</script>
