<template>
  <v-sheet outlined class="mt-1" :class="selected ? 'selected' : ''">
    <v-card-text :class="item.itemsCount ? 'light-blue lighten-5' : ''">
      <v-row class="mr-2 ml-4">
        <v-col cols="4" class="text-subtitle-1">
          <v-icon>mdi-{{ item.target }}</v-icon>
          {{ item.title }}
        </v-col>
        <v-col cols="5" class="text-subtitle-1">
          <v-chip
            small
            outlined
            v-for="(recommender, i) in item.settings"
            :key="i"
            class="mr-1 mb-2"
          >
            {{ $t(`recommender.blacklist.recommenders.${recommender}`) }}
          </v-chip>
        </v-col>
        <v-col cols="3" class="text-subtitle-1 text-end">
          <v-btn x-small icon color="primary" @click="$emit('viewInfo')">
            <v-icon>mdi-playlist-edit</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            color="primary"
            class="ml-2"
            @click="$emit('editInfo')"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            color="primary"
            class="ml-2"
            @click="$emit('deleteItem')"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
export default {
  name: "BlacklistItem",
  props: {
    item: Object,
    selected: Boolean,
  },
};
</script>

<style scoped>
.v-sheet.v-sheet--outlined.selected {
  border-color: #1e88e5;
}
</style>
