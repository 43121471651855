<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      <span v-if="item">
        {{ $t("recommender.blacklist.editGroup", item) }}
      </span>
      <span v-else>
        {{ $t("recommender.blacklist.addGroup") }}
      </span>
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-row class="mt-4">
          <v-col>
            <v-text-field
              name="email"
              :label="attributeLabels.title"
              :error-messages="blacklistErrors.title"
              v-model="blacklist.title"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :clearable="true"
              item-text="title"
              item-value="scope"
              :items="availableScopeLabels"
              :label="attributeLabels.target"
              :error-messages="blacklistErrors.target"
              v-model="blacklist.target"
            ></v-select>
          </v-col>
        </v-row>
        <div v-if="blacklist.target">
          <p class="subtitle-1 mt-3 font-weight-bold grey--text text--darken-2">
            {{ attributeLabels.settings }}:
          </p>
          <v-row>
            <v-col
              cols="6"
              v-for="(item, index) in recommenderLabels"
              :key="index"
            >
              <v-checkbox
                v-model="blacklist.settings"
                :label="item.label"
                :value="item.key"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-alert v-if="blacklistErrors.settings" dense outlined type="error">
            {{ $t("recommender.blacklist.selectRecommender") }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateItem">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import validator from "@/mixin/validator";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

import { PATCH_BLACKLIST } from "@/store/actions/recommender";

export default {
  name: "BlacklistForm",
  mixins: [validator],
  props: {
    item: Object,
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      attributeLabels: this.$t("recommender.blacklist.attributes"),
      blacklist: this.item
        ? {
            title: this.item.title,
            target: this.item.target,
            settings: _.merge([], this.item.settings),
          }
        : {
            title: "",
            target: "",
            settings: [],
          },
    };
  },
  computed: {
    ...mapGetters("service", ["items"]),
    ...mapGetters("blacklist", ["availableRecommenders", "availableScopes"]),
    recommenderLabels: function () {
      let result = [];
      let component = this;
      if (this.blacklist.target) {
        this.availableRecommenders(this.blacklist.target).forEach(function (
          recommender
        ) {
          result.push({
            key: recommender,
            label: _.get(
              component.$t("recommender.blacklist.recommenders"),
              recommender
            ),
          });
        });
      }
      return result;
    },
    blacklistErrors: function () {
      return _.merge(
        {
          title: "",
          target: "",
          settings: "",
        },
        this.validator.errors.blacklist
      );
    },
    availableScopeLabels: function () {
      let services = [];
      let component = this;
      this.items.forEach(function (service) {
        if (component.availableScopes.indexOf(service.scope) > -1) {
          services.push(service);
        }
      });
      return services;
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        let { blacklist } = this;
        this.$store
          .dispatch("blacklist/" + PATCH_BLACKLIST, {
            blacklist,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    blacklist: {
      settings: {
        required,
        minLength: minLength(1),
      },
      title: {
        required,
        maxLength: maxLength(255),
      },
      target: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
