<template>
  <v-card>
    <v-card-title
      class="headline primary lighten-1 white--text justify-space-between"
    >
      {{ $t("recommender.blacklistItem.add") }}
      <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-3" style="position: relative">
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-radio-group row v-model="importData">
          <v-radio
            :label="$t('recommender.blacklistItem.import')"
            :value="1"
          ></v-radio>
          <v-radio
            :label="$t('recommender.blacklistItem.enter')"
            :value="0"
          ></v-radio>
        </v-radio-group>
        <div v-if="importData === 1">
          <v-file-input
            persistent-hint
            :hint="$t('recommender.blacklistItem.importHint')"
            accept=".csv"
            small-chips
            truncate-length="16"
            v-model="importFile"
            :error-messages="fileError"
          ></v-file-input>
          <div class="text-end">
            <v-btn x-small color="blue darken-1" text @click="downloadExample">
              <v-icon x-small>mdi-file-delimited-outline</v-icon>
              {{ $t("downloadExample") }}
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-textarea
            autocomplete="no-autocomplete"
            persistent-hint
            :hint="$t('recommender.blacklistItem.enterItems')"
            v-model="enteredData"
          ></v-textarea>
        </div>
        <v-alert
          v-if="parsedData.length"
          text
          type="info"
          class="pa-3 mt-3 mb-0 text-caption"
        >
          {{
            $t("recommender.blacklistItem.importPreview", {
              count: parsedData.length,
            })
          }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="addItems"
          :disabled="!parsedData.length"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { PUT_BLACKLIST_ITEMS } from "@/store/actions/recommender";

export default {
  name: "BlacklistListItemForm",
  props: {
    listId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      importData: 1,
      dataLoading: false,
      parsedData: [],
      importFile: null,
      enteredData: "",
      exampleData: [
        ["100016857047374"],
        ["100035313931147"],
        ["100016572341088"],
        ["100016590949340"],
      ],
    };
  },
  computed: {
    isCsv: function () {
      return this.importFile
        ? this.importFile.name.split(".").pop() === "csv"
        : "";
    },
    fileError: function () {
      return !this.importFile || this.isCsv ? "" : this.$t("defaultErrors.csv");
    },
  },
  watch: {
    importFile: function () {
      let component = this;
      if (this.importFile && this.isCsv) {
        this.$papa.parse(this.importFile, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          header: false,
          dynamicTyping: true,
          preview: 0,
          encoding: "",
          delimitersToGuess: [",", "\t", "|", ";"],
          // ?? callback function ??header: true,
          complete: function (results) {
            component.parsedData = [];
            results.data.forEach(function (row) {
              if (row[0]) {
                component.parsedData.push(row[0]);
              }
            });
          },
        });
      } else {
        component.parsedData = [];
      }
    },
    importData: function () {
      this.parsedData = [];
      this.importFile = null;
      this.enteredData = null;
    },
    enteredData: function () {
      this.parsedData = this.enteredData
        ? this.enteredData.split("\n").filter(function (item) {
            return item;
          })
        : [];
    },
  },
  methods: {
    addItems: function () {
      this.dataLoading = true;
      const { parsedData, listId } = this;
      this.$store
        .dispatch("blacklist/blacklistItem/" + PUT_BLACKLIST_ITEMS, {
          ids: parsedData,
          listId,
        })
        .then(
          (payload) => {
            this.dataLoading = false;
            if (payload.status) {
              this.$emit("submitted");
              if (payload.data.taskHash) {
                this.$notify({
                  group: "messages",
                  title: this.$t("recommender.blacklistItem.importMessage"),
                  text: this.$t("recommender.blacklistItem.importStarted"),
                });
                this.$socket.emit("subscribe", {
                  channel: "blacklistItem",
                  subChannel: payload.data.taskHash,
                });
              }
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    downloadExample: function () {
      this.$papa.download(
        this.$papa.unparse(this.exampleData, {
          quotes: false, //or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
          columns: null, //or array of strings
        }),
        "BlacklistExample"
      );
    },
  },
};
</script>
