<template>
  <v-row>
    <v-col sm="12" md="6" lg="3">
      <v-select
        multiple
        :clearable="true"
        item-text="title"
        item-value="scope"
        :items="items"
        :label="$t('recommender.blacklist.attributes.target')"
        v-model="filters.target"
      ></v-select>
    </v-col>
    <v-col sm="12" md="6" lg="4">
      <v-text-field
        name="item"
        :label="$t('recommender.blacklist.attributes.item')"
        v-model="filters.item"
        type="text"
      ></v-text-field>
    </v-col>
    <v-col class="align-self-center">
      <v-btn x-small outlined color="red" dark @click="resetFilters">
        {{ $t("resetFilters") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import { SET_FILTER } from "@/store/actions/recommender";
export default {
  name: "BlacklistSearch",
  computed: {
    ...mapState("blacklist", ["filters", "emptyFilters"]),
    ...mapGetters("service", ["items"]),
  },
  methods: {
    resetFilters: function () {
      this.$store.commit(
        "blacklist/" + SET_FILTER,
        _.merge({}, this.emptyFilters)
      );
    },
  },
};
</script>

<style scoped>
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.v-text-field__details {
  display: none;
}
</style>
