<template>
  <v-sheet outlined class="mt-1 pa-3">
    <h3 class="px-4 pb-2">
      {{ $t("recommender.blacklistItem.listTitle", { title: listTitle }) }}
    </h3>
    <v-overlay
      :absolute="true"
      :value="dataLoading"
      justify-center
      align-center
      opacity="0.2"
    >
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <div class="px-4 pb-2 d-flex justify-space-between">
      <v-btn x-small color="primary" @click="editDialog = true">
        {{ $t("recommender.blacklistItem.add") }}
      </v-btn>
      <span>
        <v-btn
          x-small
          color="default"
          @click="statusDialog = true"
          class="mr-2 elevation-0"
          :disabled="!selectedItems.length"
        >
          {{ $t("recommender.blacklistItem.changeStatus") }}
        </v-btn>
        <v-btn
          v-if="totalCount > 0"
          x-small
          outlined
          color="red"
          @click="deleteDialog = true"
          :disabled="!selectedItems.length"
        >
          {{ $t("delete") }}
        </v-btn>
      </span>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      v-if="!dataLoading"
      hide-default-footer
      :hide-default-header="totalCount > 0 ? false : 'hide-default-header'"
      disable-filtering
      disable-pagination
      disable-sort
      :items="items"
      :no-data-text="$t('no-data')"
      :item-class="itemRowBackground"
      item-key="id"
      show-select
    >
      <template v-slot:item.enabled="{ item }">
        <div>
          <span v-if="item.enabled">
            <v-icon small color="green">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </span>
          <span v-else>
            <v-icon small color="red">
              mdi-checkbox-blank-circle-outline
            </v-icon>
          </span>
        </div>
      </template>
    </v-data-table>
    <!--    TTable pagination -->
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2">
        {{
          $t("paginationSummary", {
            from: offset + 1,
            to: maxItem,
            totalCount: totalCount,
          })
        }}
      </span>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <v-dialog v-model="editDialog" max-width="450px">
      <blacklist-list-item-form
        v-if="editDialog"
        :listId="listId"
        @close="editDialog = false"
        @submitted="
          loadItems();
          editDialog = false;
        "
      ></blacklist-list-item-form>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("deleteConfirmation") }}
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t("deleteBatchConfirmation") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">{{
            $t("close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem()">
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statusDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("recommender.blacklistItem.update") }}
          <v-btn icon color="white" @click="statusDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <p class="text-subtitle-1 mb-0 ье-2">
            {{ $t("recommender.blacklistItem.attributes.enabled") }}
          </p>
          <v-radio-group row v-model="enabled" class="mt-0">
            <v-radio
              :label="$t('recommender.blacklistItem.enabled')"
              :value="1"
            ></v-radio>
            <v-radio
              :label="$t('recommender.blacklistItem.disabled')"
              :value="0"
            ></v-radio>
          </v-radio-group>
          <v-alert text type="info" class="pa-3 mt-3 mb-0 text-caption">
            {{ $t("recommender.blacklistItem.batchUpdate") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="statusDialog = false">{{
            $t("close")
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateItem()"
            :disabled="enabled === null"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  DELETE_BLACKLIST_ITEMS,
  FETCH_BLACKLIST_ITEMS,
  PATCH_BLACKLIST_ITEMS,
} from "@/store/actions/recommender";

import BlacklistListItemForm from "@/views/recommender/blacklist-item/BlacklistListItemForm";

export default {
  name: "BlacklistListItem",
  components: { BlacklistListItemForm },
  props: {
    listId: {
      type: Number,
      required: true,
    },
    listTitle: {
      type: String,
      required: true,
    },
    selectedItem: {
      required: false,
    },
  },
  data() {
    return {
      test: null,
      dataLoading: true,
      editDialog: false,
      statusDialog: false,
      enabled: null,
      deleteDialog: false,
      selected: [],
      attributeLabels: {
        reportDateRange: "Report date",
        attribute: "Attribute",
        oldValue: "Old value",
        newValue: "New value",
      },
      headers: [
        {
          text: this.$t("recommender.blacklistItem.attributes.entityId"),
          sortable: false,
          value: "entityId",
        },
        {
          text: this.$t("recommender.blacklistItem.attributes.title"),
          value: "title",
        },
        {
          text: this.$t("recommender.blacklistItem.attributes.enabled"),
          value: "enabled",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("blacklist/blacklistItem", [
      "items",
      "pageCount",
      "pagination",
      "totalCount",
      "offset",
      "itemsPerPage",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
    selectedItems: function () {
      return _.map(this.selected, "id");
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
    listId: function () {
      this.pagination.page = 1;
      this.loadItems();
    },
  },
  methods: {
    loadItems: function () {
      return new Promise((resolve, reject) => {
        this.dataLoading = true;
        this.$store
          .dispatch("blacklist/blacklistItem/" + FETCH_BLACKLIST_ITEMS, {
            listId: this.listId,
          })
          .then(
            () => {
              this.dataLoading = false;
              resolve();
            },
            (err) => {
              console.log("Err", err);
              reject();
            }
          )
          .catch(() => {});
      });
    },
    deleteItem: function () {
      this.dataLoading = true;

      const { selectedItems, listId } = this;
      this.$store
        .dispatch("blacklist/blacklistItem/" + DELETE_BLACKLIST_ITEMS, {
          ids: selectedItems,
          listId,
        })
        .then(
          () => {
            this.dataLoading = false;
            this.deleteDialog = false;
            this.$notify({
              group: "messages",
              title: this.$t("recommender.blacklist.listTitle"),
              text: this.$t("recommender.blacklistItem.successDelete"),
            });
            this.loadItems().then(
              () => {
                if (this.items.length === 0) {
                  this.pagination.page = 1;
                }
              },
              (err) => {
                console.log("Err", err);
              }
            );
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    updateItem: function () {
      this.dataLoading = true;
      const { selectedItems, listId, enabled } = this;
      this.$store
        .dispatch("blacklist/blacklistItem/" + PATCH_BLACKLIST_ITEMS, {
          ids: selectedItems,
          listId,
          enabled,
        })
        .then(
          () => {
            this.dataLoading = false;
            this.statusDialog = false;
            this.enabled = null;
            this.$notify({
              group: "messages",
              title: this.$t("recommender.blacklist.listTitle"),
              text: this.$t("recommender.blacklistItem.successUpdate"),
            });
            this.loadItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    itemRowBackground: function (item) {
      return this.selectedItem &&
        ((item.entityId && item.entityId.includes(this.selectedItem)) ||
          (item.title && item.title.includes(this.selectedItem)) ||
          (item.domain && item.domain.includes(this.selectedItem)))
        ? "light-blue lighten-5"
        : "";
    },
  },
  mounted() {
    this.pagination.totalItems = 0;
    this.pagination.page = 1;
    this.loadItems();
  },
  sockets: {
    blacklistItem: function (msg) {
      this.loadItems();
      const vm = this;
      const { payload } = msg;
      if (payload && payload.status === "completed") {
        this.$notify({
          group: "messages",
          closeOnClick: true,
          duration: 50000,
          title: this.$t("recommender.blacklist.listTitle"),
          text: this.$t("recommender.blacklistItem.importFinished", payload),
        });
      }
      if (payload && payload.errors && payload.errors.exists) {
        payload.errors.exists.forEach(function (item) {
          vm.$notify({
            group: "messages",
            closeOnClick: true,
            duration: 50000,
            type: "error",
            title: vm.$t("recommender.blacklist.listTitle"),
            text: vm.$t("recommender.blacklistItem.alreadyExist", item),
          });
        });
      }
    },
  },
};
</script>
